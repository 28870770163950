.informationsTicket{
  background-color: white;
  text-align: center;
  font-size: 17px;
  width: 100%;
  font-family: "Open sans"; 
}

.roundedBottom {
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
}

.topHeaderMessagerie{
  background: #39b6b8;
  font-size: 17px;
  color: white;
  font-family: "Open sans";
  width: 100%;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}

.headerMessagerie{
  background: #39b6b8;
  font-size: 18px;
  color: white;
  font-family: "Open sans";
  width: 100%;
}
.element{
  margin-top: -1%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.element:hover{
  width: auto;
  overflow: visible;
  white-space:inherit;
}

.insideMessagerie{
  background-color: white;
  width: 100%;
  font-family: "Open sans";
  color: black;
  height: 65%;
}

.insideMessagerieCloture{
  background-color: white;
  width: 100%;
  font-family: "Open sans";
  color: black;
  height: 65%;
}


/* Message */
.textDiscussion{
  margin-top: 1%;
  font-size: 26px;
}

.textName{
  color: rgb(0, 0, 0);
  font-weight: bold;
}

@media screen and (max-width: 640px) {
  .d-none-mobile { display: none; }
}


/* Echange message */
.messages {
  /* Flexbox-specific styles */
  display: flex;
  flex-direction: column;

  /* General styling */
  font: 16px/1.3 'Open sans';
  list-style-type: none;
  margin: 0 auto;
  padding: 8px;
  overflow: auto;
}

.headerMessageDestinataire{
  margin-bottom: 1%;
}

.headerMessageEmetteur{
  margin-bottom: 1%;
  display: flex;
  flex-direction: row-reverse;
  margin-right: 1%;
}

.messages p.destinataire {
  background: #Dce4eb;
  border-radius: 8px;
  padding: 12px;
  margin: 2px 8px 2px 0;
  float: left;
  width: 45%;
}

.messages p.emetteur {
 float: right;
  margin: 2px 0 2px 8px;
  border-radius: 8px;
  padding: 12px;
  width: 45%;
  background-color: #e1f5fe!important;
}

.emetteur p:last-child, .destinataire p:last-child {
  margin-bottom: 0;
}

@media screen and (max-width: 640px) {
  .messages p.destinataire, .messages p.emetteur {
    width: 95%;
  }
}

/* ---------- */
.textWriteMessage{
  margin-left: 1%;
  font-size: 20px;
}

.writeMessage{
  margin-top: 2%;
  margin-bottom: 2%;
}

.btnFichierMessage{
  background-color: DodgerBlue;
  border: none;
  color: white;
  padding: 2px 4px;
  cursor: pointer;
  font-size: 13px;
}

.btnFichierMessage:hover{
  filter: brightness(90%);
  background-color: DodgerBlue;
  border: none;
  color: white;
  padding: 2px 6px;
  cursor: pointer;
  font-size: 13px;
}

// note ticket support
.note{
  background-color: white;
  text-align: center;
  font-size: 17px;
  width: 100%;
  font-family: "Open sans"; 
}
.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}
.btnValiderNote{
  color: white;
  background-color: #21c1d6;
  cursor: pointer;
}

.btnValiderNote:hover{
  color: white;
  background-color: #21c1d6;
  filter: brightness(90%);
  cursor: pointer;
}

/* Chrono */

.chrono {
  display: flex;
  justify-content: center;
}

.chrono p {
  font-size: 36px;
}
.time {
  display: flex;
  justify-content: center;
  font-family: "Open sans";
  font-size: 15px;
  margin-top: -25px;
  color: gray;
  margin-left: 25px;
}

.minute {
  padding: 0px 30px;
}

.button {
  margin-top: 5px;
  margin-bottom: 5px;
}

.up {
  padding: 7px 25px;
  border-radius: 8px;
  background-color: white;
  border: 2px solid;
  margin-left: 10px;
}

.up:hover {
  color: green;
}

.down {
  padding: 7px 25px;
  border-radius: 8px;
  background-color: white;
  border: 2px solid;
  margin-left: 10px;
}

.down:hover {
  color: red;
}

.edit{
  padding: 7px 25px;
  border-radius: 8px;
  background-color: white;
  border: 2px solid;
  margin-left: 10px;
}
.edit:hover{
  color: rgb(197, 113, 3);
}
.chronoModal{
  display: flex;
  justify-content: center;
}

.chronoModal p {
  font-size: 50px;
}

.Countdown-displayTop {
  margin-top: 2%;
  text-align: center;
}

.Countdown-displayBot {
  text-align: center;
  margin-top: -2.5%;
}
.Countdown-display button {
  margin: 0 15px;
  border: solid 1px transparent;
  border-radius: 2px;
  padding: 4px 16px;
  color: #ffffff;
  font-size: 16px;
  background-color: #106ba3;
}
.Countdown-display button:hover {
  filter: brightness(90%);
}

.chevronDown{
  margin-top: 1.2%;
}










