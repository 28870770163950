.selectButton {
  color: #fff;
  background-color: #8898aa;
  border-color: #8898aa;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08); }
  .selectButton:hover {
    color: #fff;
    background-color: #72859a;
    border-color: #6a7e95; }
  .selectButton:focus, .selectButton.focus {
    background-color: #757a80;
  }
  .selectButton.disabled, .selectButton:disabled {
    color: #fff;
    background-color: #8898aa;
    border-color: #8898aa; }
  .selectButton:not(:disabled):not(.disabled):active, .selectButton:not(:disabled):not(.disabled).active,
  .show > .selectButton.dropdown-toggle {
    color: #fff;
    background-color: #6a7e95;
    border-color: #65788d; }
    .selectButton:not(:disabled):not(.disabled):active:focus, .selectButton:not(:disabled):not(.disabled).active:focus,
    .show > .selectButton.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(136, 152, 170, 0.5); }