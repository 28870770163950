.bg-info-gradient {
  background: #39b6b8;
}
.tddate{
  width: 14%;
}
.tdNomSociete{
  width: 14%;
}
.tdStatut{
  width: 8%;
  font-size: 16px;
}
.tdSujet{
  width: 30%;
}
tbody tr{
  height: 50px;
}
.trTab{
  cursor: pointer;

}
.trTabTechnicien{
  background-color: rgba(207, 208, 209,0.4);
  cursor: pointer;
}
.table-hover .trTabTechnicien:hover{
  background-color: rgba(207, 208, 209,0.3);
  cursor: pointer;

}
.noteTd{
  width: 15%;
}
.dateTd {
  width: 9%;
}
.intervenant{
  width: 8%;
}
.sujetTd{
  width: 18%;
}
.th{
  font-size: 13px;
}
.table th, .table td {
    vertical-align: middle;
}

/* Notification ticket pas encore répondu */
.notify {
    position: relative;
}
.notify .heartbit {
    // position: absolute;
    // top: 21px;
    // right: 42px;
    height: 18px;
    width: 18px;
    z-index: 0;
    border: 3px solid #c52643;
    border-radius: 70px;
    animation-duration: 2.5s;
    -moz-animation-iteration-count: infinite;
    -o-animation-iteration-count: infinite;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
}
.notify .point {
    width: 6px;
    height: 6px;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    border-radius: 30px;
    background-color: #c52643;
    // position: absolute;
    // right: 48px;
    // top: 27px;
}

.notify-new {  
  position: relative;
  left: 5px;
  top: -20px;
  width: 15px;
  height: 100%;
}

.pulse {
  position: absolute;
  top: 10px;
  transform: translate(-50%, -50%);
  left: -4px;
  height: 20px;
  width: 20px;    
  z-index: 10;  
  border: 5px solid #ef5350;
  border-radius: 70px;
  animation: pulse 1s ease-out infinite;
}

.marker {
  position: absolute;
  top: 15px;
  left: 1px;
  height: 10px;
  width: 10px;  
  border-radius: 70px;
  background: red;
}

@keyframes pulse {
  0% {
      -webkit-transform: scale(0);
      opacity: 0.0;
  }

  25% {
      -webkit-transform: scale(0.1);
      opacity: 0.1;
  }

  50% {
      -webkit-transform: scale(0.5);
      opacity: 0.3;
  }

  75% {
      -webkit-transform: scale(0.8);
      opacity: 0.5;
    }

  100% {
      -webkit-transform: scale(1);
      opacity: 0.0;
  }
} 