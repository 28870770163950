/* Formulaire création ticket */
.formTicketCreation {
    background-color: white;
    margin-top: 2%;
    margin-left: 5%;
}

.header{
    background: #39b6b8;
    font-size: 20px;
    color: white;
    font-family: "Open sans";
    height: 50px;
    width: 100%;
}

.formTicket{
    background-color: white;
    width: 100%;
}

.choixTicket{
    font-family: "Open sans";
    font-size: 20px;
    color: black;
    width: 100%;
}
.textarea{
    resize:none;
}


.btnSend, .btn-connection { background-color: #21c1d6 }
.btnSend:hover, .btn-connection:hover {
    background-color: #21c1d6;
    border-color: #21c1d6;
    filter: brightness(90%);
}
.btn-connection {
    border-radius: 16px;
    font-size: smaller;
}

/* Card information */
.informations{
    background-color: white;
    text-align: center;
    font-size: 15px;
    width: 100%;
    font-family: "Open sans";   
}
.titleHeaderInfo{
    text-align: center;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
}
.nom {
    margin-top: 2%;
}

.nom,.prenom,.email,.tel,.adresse{

    color: black;
}

.span{
    color: #525f7f;
}

.btnEdit{
    background-color: #21c1d6;
    font-size: 15px;
}

.btnEdit:hover{
    background-color: #21c1d6;
    border-color: #21c1d6;
    filter: brightness(90%);
}
.divBtnSend{
    padding: 5%;
    padding-top: 2%;
    padding-right: 2%;
}

/* Déco */
.btnModal{
    font-size: 18px;
    background-color: #1e88e5;
    cursor: pointer;
    
}
.btnModal:hover{
    background-color: #1e88e5;
    filter: brightness(90%);
}
.btnModalNo{
    margin-left: 5%;
    background-image: linear-gradient(-20deg,#e20505 -35%,#b44c4c)!important;
}

.btnModalNo:hover{
    margin-left: 5%;
    background-image: linear-gradient(-20deg,#e20505 -35%,#b44c4c)!important;
    filter: brightness(90%);
}
.btnModalYes{
    margin-right: 3%;
}
.textDeco{
    font-size: 16px;
}

/* Modal pièce jointe */
.elementArea{
    display: block;
}

.btnFichierModal{
    font-size: 13px;
    cursor: pointer;
    background-color: #21c1d6;
    color: white;
}

.btnFichierModal:hover{
    font-size: 13px;
    cursor: pointer;
    background-color: #21c1d6;
    filter: brightness(90%);
    color: white;
}

.btnValider {
    font-size: 13px;
    cursor: pointer;
    background-color: #21c1d6;
    color: white;
}

.btnValider:hover {
    font-size: 13px;
    cursor: pointer;
    background-color: #21c1d6;
    filter: brightness(90%);
    color: white;
}

thead{
    text-align: center;
}

.btnFichier{
    cursor: pointer;
    background-color: #21c1d6;
    color: white;
}

.btnFichier:hover{
    cursor: pointer;
    background-color: #21c1d6;
    filter: brightness(90%);
    color: white;
}


.divBtnAddFichier{
    margin-left: auto;
}
tfoot{
    border-top: none;
}

.selectElement{
    font-size: 14px;
}
 









