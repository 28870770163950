/*******************************/
// Bootstrap overrides
/*******************************/

//Theme core files
@import 'core/theme-colors/theme-colors';
@import 'core/layout/layout';
@import 'core/topbar/topbar';
@import 'core/sidebar/sidebar';


// Theme pages
@import 'pages/authentication/authentication';
@import 'pages/ticket/ticketCreation';
@import 'pages/ticket/listeTicket';
@import 'pages/ticket/informationTicket';
@import 'pages/profil/profil';
@import 'pages/statistiques/client';
@import 'pages/statistiques/ticket';
@import 'pages/statistiques/cloture';
@import '~react-datepicker/dist/react-datepicker.css';


.page-titles {
    padding: 15px 30px;
    background: #fff;
    box-shadow: 1px 0 5px rgba(0,0,0,0.1);
    margin-bottom: 15px;
  }
  .page-titles .text-themecolor {
    color: #1e88e5;
  }
  
  .page-titles .breadcrumb {
    padding: 0;
    margin: 0;
    background: 0 0;
    font-size: 14px;
  }
  .breadcrumb {
    display: flex;
    flex-wrap: wrap;
    padding: 0 0;
    margin-bottom: 1.5rem;
    list-style: none;
    background-color: #eef5f9;
    color: #1e88e5 ;
  }
  .breadcrumb-item.active {
    color: #6c757d;
  }
  
  .page-titles .breadcrumb .breadcrumb-item+.breadcrumb-item::before {
    content: "\e649";
    font-family: "Open sans";
    color: #ced4da;
    font-size: 11px;
  }
  .link {
      color: #1e88e5;
  }

  .btn-secondary {
    border-color: #55d8d8  ;
    box-shadow: none;
  }
  .createBtn{
    background-color: #55d8d8 ;
    margin-top: -0.2%;
  }
  .createBtn:hover{
    background-color: #55d8d8 ;
    border-color: #55d8d8 ;
    filter: brightness(90%);
  }

  .backButton{
    background-color: #21c1d6;
    border-radius: 30px;
    cursor: pointer;
  }
  .backButton:hover{
    background-color: #21c1d6;
    border-color: #21c1d6;
    filter: brightness(90%);
    cursor: pointer;
  }

  .bg-successV2 {
  background-color: #02c912 !important; }

a.bg-successV2:hover, a.bg-successV2:focus,
button.bg-successV2:hover,
button.bg-successV2:focus {
  background-color: #34c23b !important; }
  .apexcharts-text tspan {
    font-family: "Open sans" !important;
  }

  .Modal {
    top: 26.7%;
    transform: translate(-50%, -50%);
  }
  .table-responsive {
    z-index: 0;
  }