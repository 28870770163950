.nomSocieteNonActive{
    background-color: gray;
    color: white;
    padding: 5px;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 14px;
}
.nomSocieteActive{
    background-color: #1e88e5;
    color: white;
    padding: 5px;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 14px;
}

.btnEditProfil {
    margin-left: auto;
    margin-right: 2%;
    background-color: #21c1d6;
    border-color: #21c1d6;
    color: white;
    cursor: pointer;
}
.btnEditProfil:hover {
    margin-left: auto;
    margin-right: 2%;
    background-color: #21c1d6;
    border-color: #21c1d6;
    color: white;
    cursor: pointer;
    filter: brightness(90%);
}